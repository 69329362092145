import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatTabsModule,ReactiveFormsModule,MatFormFieldModule,NgClass,
        MatIconModule,MatTooltipModule,MatCardModule, MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,],
})
export class SettingsComponent implements OnInit {
    password_form!: FormGroup;
    submitted= false;
    hide1 = true;
    hide2 = true;
    hide3 = true;
    constructor(public fb: FormBuilder,public _authService : AuthService,
      public _snackBar : MatSnackBar,public router:Router
    ){

    }
    ngOnInit(): void {
      this.password_form = new FormGroup({
        old_password: new FormControl('', Validators.required),
        new_password: new FormControl('', Validators.required),
        confirm_password: new FormControl('', Validators.required),
        _id : new FormControl()
      });
    }
    bringToken(): string {
        const token = this._authService.accessToken;
        this.decodeToken(token)
        return token;
        
    }
    decodeToken(token: string) {
        const decodedToken = AuthUtils._decodeToken(token);
        return decodedToken;
    }
    filter(event:any){
        // console.log(event.target.value)
        // console.log(this.userData.password)
      }
    initChangePWDForm() {
        this.password_form = this.fb.group({
          old_password: new FormControl('', Validators.required),
          new_password: new FormControl('', Validators.required),
          confirm_password: new FormControl('', Validators.required),
          _id : new FormControl()
        })
      }
      get form() {
        return this.password_form.controls;
      }
      changePWDForm(values: any) {
        this.submitted = true;
        if (this.password_form.invalid) {
          return;
        }
        if (this.form['old_password']?.value === this.form['new_password']?.value) {
          this._snackBar.open('Your new password must differ from the current password.', '', {
            duration: 4000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['snackbar-danger']
          });
        } 
        else if(this.form['new_password']?.value == 'Bookings@2024'){
          this._snackBar.open('New password is not acceptable', '', {
            duration: 4000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['snackbar-danger']
          });
         } 
        else if (this.form['new_password']?.value !== this.form['confirm_password']?.value) {
          this._snackBar.open('New password and confirmation must be same. Please try again.', '', {
            duration: 4000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['snackbar-danger']
          });
           }
         
        else {
            let token = this.bringToken()
            let decodedResult = this.decodeToken(token)
          let data = {
            oldPassword: values.old_password,
            password: values.new_password,
            user_id: decodedResult.id
          }
          values._id = decodedResult.id
          this._authService.resetPassword(values).subscribe((res: any) => {
            if (res && res['status'] === 200) {
              this._snackBar.open('Password has been updated successfully! Please log in with your new password.', '', {
                duration: 4000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: ['snackbar-success']
              });
              this.password_form.reset();
              this.router.navigate(['/sign-out']);
            } else {
              this._snackBar.open(res['message'], '', {
                duration: 4000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: ['snackbar-danger']
              });
            //   this.showNotification('snackbar-danger', res['message'], 'top', 'center');
            }
    
          }, (err) => {
            this._snackBar.open(err, '', {
              duration: 4000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['snackbar-danger']
            });
          })
    
        }
      }
}
