<div class="flex w-full flex-auto flex-col">
  <div class="mx-auto flex w-full max-w-screen-xl flex-wrap p-6 md:p-8">
    <!-- Title and action buttons -->
    <div class="flex w-full items-center justify-between">
      <div>
        <div class="text-3xl font-semibold leading-8 tracking-tight">
          Change Password
        </div>
      </div>
    </div>

    <div class=" mt-8 bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow sm:col-span-2 md:col-span-4">
    <div class="mt-5">
      <form [formGroup]="password_form">
        <mat-form-field style="width: 100%;">
          <mat-label>Current Password</mat-label>
          <input matInput type="password" formControlName="old_password"
          [type]="hide1 ? 'password' : 'text'"
          [ngClass]="{ 'is-invalid': submitted && password_form.get('old_password')?.invalid }"
          placeholder="Current Password">
        <button mat-icon-button matSuffix (click)="hide1 = !hide1"
          [matTooltip]="hide1 ? 'Show Password' : 'Hide Password' "
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
          <mat-icon>{{ hide1 ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
          @if(password_form.get('old_password')?.invalid){
            <mat-error>Current Password is Required</mat-error>
          }
        </mat-form-field>
        <mat-form-field style="width: 100%; padding-top: 5px;">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="new_password"
          [type]="hide2 ? 'password' : 'text'"
          [ngClass]="{ 'is-invalid': submitted && password_form.get('new_password')?.invalid }"
          placeholder="New Password">
        <button mat-icon-button matSuffix (click)="hide2 = !hide2"
          [matTooltip]="hide2 ? 'Show Password' : 'Hide Password' "
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
          <mat-icon>{{ hide2 ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
          @if(password_form.get('new_password')?.invalid){
            <mat-error>New Password is Required</mat-error>
          }
        </mat-form-field>
        <mat-form-field style="width: 100%; padding-top: 5px;">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="confirm_password"
          [type]="hide3 ? 'password' : 'text'"
          [ngClass]="{ 'is-invalid': submitted && password_form.get('confirm_password')?.invalid }"
          placeholder="New Password">
        <button mat-icon-button matSuffix (click)="hide3 = !hide3"
          [matTooltip]="hide3 ? 'Show Password' : 'Hide Password' "
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
          <mat-icon>{{ hide3 ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
          @if(password_form.get('confirm_password')?.invalid){
            <mat-error>Confirm Password is Required</mat-error>
          }
        </mat-form-field>
        <div style="padding-top: 15px;">
          <button 
            mat-raised-button
            color="accent"
            (click)="changePWDForm(password_form.value)"
          >
          Update
          Password
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>